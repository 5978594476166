import React, { createContext, useMemo, useCallback, ReactNode } from 'react'

import { Authenticated, JWTValues } from './constants'

import { usePostStartSessionAction, StartSessionPayload } from '@commonstock/common/src/api/auth'
import { useGetMyProfile, UserProfile } from '@commonstock/common/src/api/profile'
import { useClient } from '@commonstock/client/src/react/context'
import useInterval from '@use-it/interval'
import { authenticator, useAuthTokens, logout } from '../../client/authenticator'
import jwt_decode from 'jwt-decode'
import { UserRole } from '@commonstock/common/src/types'
import jsCookie from 'js-cookie'
import config from 'src/config'

type AuthValue = {
  authenticated: Authenticated
  startSession: (sessionStartToken: string | null) => Promise<StartSessionPayload>
  userUuid: string
  isModerator: boolean
  isAdmin: boolean
  isEmployee: boolean
  myProfile?: UserProfile
}

const AuthContext = createContext<AuthValue | void>(undefined)
AuthContext.displayName = 'AuthContext'

function AuthProvider({ children }: { children: ReactNode }) {
  let [tokens, setTokens, tokensPending] = useAuthTokens()
  const postStartSession = usePostStartSessionAction()
  const setDomainIDCookie = useDomainIDCookie()

  let client = useClient()
  const authenticated = tokensPending ? Authenticated.Pending : !tokens?.access ? Authenticated.No : Authenticated.Yes

  const [myProfile] = useGetMyProfile(null, { paused: authenticated !== Authenticated.Yes })

  // perform the token refresh every 15s, refresh if tokens expire within next 30 seconds
  useInterval(() => authenticator(client, 30000), 15000)

  let startSession = useCallback(
    (startSessionToken: string | null): Promise<StartSessionPayload> => {
      return postStartSession({ headers: { authorization: `Bearer ${startSessionToken}` } })
        .then(async res => {
          if (res.success?.payload.confirmed) {
            // if startSession returns false, we are not verified
            let newTokens = {
              access: res.success.payload.jwt.access_token,
              refresh: res.success.payload.jwt.refresh_token
            }
            setTokens(newTokens)
            // this is user for subdomain identification
            setDomainIDCookie(jwt_decode<JWTValues>(newTokens.access).uuid, res.success.payload.address)
          }
          // @NOTE this should never happen
          if (!res.success) throw new Error('start-session failed')
          return res.success.payload
        })
        .catch(err => {
          if (err.fail && err.fail.status === 403) {
            // @TODO Monolith appears to return a 403 when a bad token is used but should probably be a 401 instead. Need to revisit.
            console.error('!! postStartSession: 403 - user will be logged out')
            logout(client)
          }
          // @TODO: handle error with logout, retry, or displaying error to user.
          console.error('## postStartSession err:', err)
          throw err
        })
    },
    [postStartSession, setTokens, setDomainIDCookie, client]
  )

  const userUuid = useMemo(() => {
    const accessToken = tokens?.access
    return accessToken ? jwt_decode<JWTValues>(accessToken).uuid : ''
  }, [tokens])

  const isModerator = useMemo(() => {
    const accessToken = tokens?.access
    return accessToken ? jwt_decode<JWTValues>(accessToken)?.roles?.some(r => r === UserRole.moderator) : false
  }, [tokens])

  const isEmployee = useMemo(() => {
    const accessToken = tokens?.access
    return accessToken ? jwt_decode<JWTValues>(accessToken)?.roles?.some(r => r === UserRole.employee) : false
  }, [tokens])

  const isAdmin = useMemo(() => {
    const accessToken = tokens?.access
    return accessToken ? jwt_decode<JWTValues>(accessToken)?.roles?.some(r => r === UserRole.admin) : false
  }, [tokens])

  let value: AuthValue = useMemo(
    () => ({
      authenticated,
      startSession,
      userUuid,
      isModerator,
      isAdmin,
      isEmployee,
      myProfile
    }),
    [startSession, userUuid, isModerator, isAdmin, isEmployee, myProfile, authenticated]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

function useAuth(): AuthValue {
  const context = React.useContext(AuthContext)

  // convenience jest stub
  if (process.env.NODE_ENV !== 'production' && typeof jest !== 'undefined')
    return {
      authenticated: Authenticated.Yes,
      startSession: async () => ({} as any),
      userUuid: '1',
      isModerator: false,
      isAdmin: false,
      isEmployee: false
    }
  return (context || {}) as AuthValue
}

/**
 * A small wrapper around useGetUser to pause until the user is authenticated
 */
const useUser = () => {
  const { authenticated } = useAuth()
  return useGetMyProfile(null, { paused: authenticated !== Authenticated.Yes })
}

export { AuthProvider, useAuth, useUser }

const ID_COOKIE_KEY = 'domainId'
function useDomainIDCookie() {
  const cookie = jsCookie.withAttributes({ domain: '.' + config.topLevelDomain })

  const set = (uuid: string, address: string) => {
    return cookie.set(ID_COOKIE_KEY, btoa(uuid + '::' + address))
  }

  return set
}
